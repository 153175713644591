import Vue from 'vue';

Vue.directive('sandbox', {
  inserted: function (el, binding, vnode) {
    const user = vnode.context.$store.getters.username;
    const admin = [
        'admin',
        'alex',
        'pavel.m@parcelpoint.us',
        'sasha.demin@parcelpoint.us',
        'mikhail.lipatov@parcelpoint.us',
        'ceo@parcelpoint.us',
        'amir.z@parcelpoint.us',
        'aleksandra.lukyanchuk@parcelpoint.us',
        'veronika.m@parcelpoint.us',
        'sergei.n@stockfore.us',
        'yevgeniya.t@stockfore.us',
        'vlad.fedorov@parcelpoint.us',
        'maxim.budanov@parcelpoint.us'
      ];
    const isAdmin = admin.some(el => el === user);

    if (isAdmin) {
      el.style.display = 'block';
    } else {
      el.style.display = 'none';
    }
  }
});
