<template>
  <v-card width="256" flat>
    <v-navigation-drawer permanent floating color="grey">
      <v-list nav class="pa-0">
        <!--        <router-link to="home" v-slot="{ href, route, navigate, isActive, isExactActive }">-->
        <!--          <v-list-item class="ma-0 active" @click="goToPage('home')" v-if="isActive">-->
        <!--            <heroicon name="widgetBold" fill="#008233" class="mr-2 ml-1" heigh="20" width="35"/>-->
        <!--            <span class="active-title">Home</span>-->
        <!--          </v-list-item>-->
        <!--          <v-list-item to="home" @click="goToPage('home')" class="ma-0" v-else>-->
        <!--            <heroicon name="widgetBold" fill="#666666" class="mr-2 ml-1" heigh="20" width="35"/>-->
        <!--            <span>Home</span>-->
        <!--          </v-list-item>-->
        <!--        </router-link>-->
        <template v-if="menus.length <= 2" v-for="(screen, i) in menus">
          <div
            @click="goLink"
            class="my-5 px-6"
            style="cursor: pointer; color:red;"
          >
            <v-icon class="mr-2" color="red">open_in_new</v-icon>
            <span>Go to new version</span>
          </div>
          <router-link
            v-for="(view, j) in screen.permissions"
            :key="`view-${view.description}`"
            :to="view.view"
            v-slot="{ href, route, navigate, isActive, isExactActive }"
            v-if="!!view?.view"
          >
            <v-list-item
              class="ma-0 active"
              @mousedown="goToPage(view.view)"
              v-if="isActive"
            >
              <heroicon
                :name="view.icon"
                fill="#008233"
                class="mr-3 ml-2"
                heigh="20"
                width="35"
              />
              <v-list-item-title class="active-title">{{
                view.description
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item class="ma-0" @click="goToPage(view.view)" v-else>
              <heroicon
                :name="view.icon"
                fill="#666666"
                class="mr-3 ml-2"
                heigh="20"
                width="35"
              />
              <v-list-item-title>{{ view.description }}</v-list-item-title>
            </v-list-item>
          </router-link>
        </template>
        <template>
          <div>
            <div
              @click="goLink"
              class="my-5 px-6"
              style="cursor: pointer; color:red;"
            >
              <v-icon class="mr-2" color="red">open_in_new</v-icon>
              <span>Go to new version</span>
            </div>
            <template
              v-if="screen.screen.value === 'GENERAL' && menus.length > 2"
              v-for="(screen, i) in menus"
            >
              <router-link
                v-for="(view, j) in screen.permissions"
                :key="`view-${view.description}`"
                :to="view.view"
                v-slot="{ href, route, navigate, isActive, isExactActive }"
                v-if="!!view?.view"
              >
                <v-list-item
                  class="ma-0 active"
                  @click="goToPage(view.view)"
                  v-if="isActive"
                >
                  <heroicon
                    :name="view.icon"
                    fill="#008233"
                    class="mr-3 ml-2"
                    heigh="20"
                    width="35"
                  />
                  <v-list-item-title class="active-title">{{
                    view.description
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item class="ma-0" @click="goToPage(view.view)" v-else>
                  <heroicon
                    :name="view.icon"
                    fill="#666666"
                    class="mr-3 ml-2"
                    heigh="20"
                    width="35"
                  />
                  <v-list-item-title>{{ view.description }}</v-list-item-title>
                </v-list-item>
              </router-link>
            </template>
            <v-expansion-panels>
              <v-expansion-panel
                style="background-color: #fafafa"
                class="pa-0"
                v-for="(route, j) in menus"
                :key="j"
                v-if="route.screen.value !== 'GENERAL' && menus.length > 2"
              >
                <v-expansion-panel-header class="px-4">
                  <div class="d-flex align-center">
                    <v-icon left class="">
                      {{ route.screen.icon }}
                    </v-icon>
                    <div>{{ route.screen.description }}</div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0">
                  <router-link
                    class="pa-0"
                    v-for="(view, j) in route.permissions"
                    :key="`view-${view.view}`"
                    :to="view.view"
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                    v-if="!!view?.view"
                  >
                    <v-list-item
                      class="ma-0 active"
                      @click="goToPage(view.view)"
                      v-if="isActive"
                    >
                      <heroicon
                        :name="view.icon"
                        fill="#008233"
                        class="mr-3 ml-2"
                        heigh="20"
                        width="35"
                      />
                      <v-list-item-title class="active-title">{{
                        view.description
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      class="ma-0"
                      @click="goToPage(view.view)"
                      v-else
                    >
                      <heroicon
                        :name="view.icon"
                        fill="#666666"
                        class="mr-3 ml-2"
                        heigh="20"
                        width="35"
                      />
                      <v-list-item-title>{{
                        view.description
                      }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-sandbox>
                <v-expansion-panel-header>
                  <div>Sandbox</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0">
                  <v-list-item class="ma-0">
                    <router-link to="/sandbox/report" class="link-report">
                      Report
                    </router-link>
                  </v-list-item>
                  <v-list-item class="ma-0">
                    <router-link to="/sandbox/new-tariff" class="link-report">
                      New tariff
                    </router-link>
                  </v-list-item>
                  <v-list-item class="ma-0">
                    <router-link to="/sandbox/edit-parcel" class="link-report">
                      Edit parcel
                    </router-link>
                  </v-list-item>
                  <v-list-item class="ma-0">
                    <router-link to="/sandbox/export" class="link-report">
                      Marina's reports
                    </router-link>
                  </v-list-item>

                  <v-list-item
                    class="ma-0"
                    v-if="
                      [
                        'pavel.m@parcelpoint.us',
                        'sasha.demin@parcelpoint.us',
                        'sergei.n@stockfore.us',
                        'amir.z@parcelpoint.us',
                        'yevgeniya.t@stockfore.us',
                        'aleksandra.lukyanchuk@parcelpoint.us',
                        'maxim.budanov@parcelpoint.us'
                      ].includes(user.email)
                    "
                  >
                    <router-link
                      to="/sandbox/export-logistic"
                      class="link-report"
                    >
                      Logistics report
                    </router-link>
                  </v-list-item>

                  <v-list-item class="ma-0">
                    <router-link to="/sandbox/hs-tree" class="link-report">
                      HS Tree
                    </router-link>
                  </v-list-item>
                  <v-list-item class="ma-0">
                    <router-link to="/sandbox/hs-directory" class="link-report">
                      HS Directory
                    </router-link>
                  </v-list-item>
                  <v-list-item class="ma-0">
                    <router-link to="/sandbox/hs-bulk" class="link-report">
                      HS Bulk Processing
                    </router-link>
                  </v-list-item>
                  <v-list-item class="ma-0">
                    <router-link
                      to="/sandbox/consolidations"
                      class="link-report"
                    >
                      Consolidations
                    </router-link>
                  </v-list-item>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { Api } from '../api/http';
import { mapState } from 'vuex';
import goTo from 'vuetify/lib/services/goto';

export default {
  name: 'app-drawer',
  components: {
    VuePerfectScrollbar,
  },
  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
    drawWidth: {
      type: [Number, String],
      default: '350',
    },
    value: Boolean,
  },
  data: () => ({
    mini: false,
    menus: null,
    scrollSettings: {
      maxScrollbarLength: 160,
    },
    isShowMenu: false,
  }),
  computed: {
    ...mapState(['user']),

    active() {
      return (views) => !!views.find((v) => '/' + v.view === this.$route.path);
    },
  },

  methods: {
    goLink() {
      const alterUrl = process.env.VUE_APP_ALTER_URL;
      if (alterUrl) {
        window.location.href = alterUrl;
      } else {
        console.error('VUE_APP_ALTER_URL не задана в файле .env');
      }
    },
    goToPage(page) {
      if (event.ctrlKey) {
        window.open('/' + page, '_blank');
        return;
      }

      const scrollPosition = window.scrollY;

      if (scrollPosition > 30) {
        const scrollStep = -scrollPosition / 20;
        const scrollAnimation = () => {
          if (window.scrollY > 0) {
            window.scrollBy(0, scrollStep);
            requestAnimationFrame(scrollAnimation);
          } else {
            this.$router.push('/' + page);
          }
        };
        requestAnimationFrame(scrollAnimation);
      } else {
        this.$router.push('/' + page);
      }
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
  mounted() {
    if (Api.cli.loggedIn) {
      Api.menu.menu().then((r) => {
        if (
          this.$store.getters.checkAccess({
            permission: 'TRANSIT_STOCK',
            c: true,
          }) &&
          !this.$store.getters.checkAccess({
            permission: 'ADD_NEW_PARCEL',
            c: true,
          })
        ) {
          this.$router.push('/dubai');
        }
        this.menus = r;
      });
    }
  },
};
</script>

<style>
.active {
  background: #0082331f;
}

.active-title {
  color: #008233;
}

.v-expansion-panel-content__wrap {
  padding: 0 8px 16px !important;
  flex: 1 1 auto;
  max-width: 100%;
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0 !important;
}

.v-expansion-panel {
  box-shadow: none !important;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  display: none !important;
}

.link-report {
  color: #000 !important;
  text-decoration: none;
  background: #fff;
}
</style>
